export const REVIEWS_DATA = [
    {
        "name": "Al Hearn", 
        "comment": "This product is one of the best genuine bargains out there. They are continually improving it and adding new features. They also listen to their customers. I am a happy customer.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Al-Hearn.png"

    },
    {
        "name": "Ampaipan Boonthai", 
        "comment": "I used the diagram for research presentation, worked well!  I love SW! Really recommend it!  I use on MacOs and it works on all 3 of my devices (iMac and MacBook)", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Ampaipan-Boonthai.png"
    },





    {
        "name": "Amy Wees", 
        "comment": "I bought it from another Facebook ad I saw and I love it! Using it to create great presentations. At first I thought it might be the same as Canva but it isn't, it's great for making cool drawings and presentations! And it's a great price!", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Amy-Wes.png"
    },
    {
        "name": "Corinne Bayle", 
        "comment": "Hello, \nI've bought the sketchwow software this week and I love it!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Corinne.png"
    },



    {
        "name": "Eisa Ibrahim", 
        "comment": "Dear Support, Woooo000000000w,", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Eisa.png"
    },

    {
        "name": "Nikki Tester", 
        "comment": "I got mine today and it\u2019s honestly brilliant", 
        "emoji": "emoji-care", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB- Nikki Tester.png"
    },

    {
        "name": "NaOubo8i2ki5h7", 
        "comment": "Super, thanks Jason  I've done the upgrade.  I really love the software.  Do you have any videos showing how you've created the templates? They look so great, and it'd be useful to see how 2 or 3 were put together. I'm starting to get to grips with it. One I did today:  What I LOVE doing AND earns well High value work  that I could do", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Dan Harrison.png"
    },

    {
        "name": "Austin Carroll", 
        "comment": "The software is fantastic, and your responses to the FB trolls are very respectable. Thanks for the great LTD and taking the high road. Both are quite refreshing in this day and age", 
        "emoji": "emoji-care", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Austin-Carroll.png"
    },


    {
        "name": "Afi Royal", 
        "comment": "Got my copy already and I love it. Just browsed through the templates, and didn't create anything yet. Just kinda tinkered with it. It's too dope. If you create webinars the Pro version is a must have.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Afi-Royal.png"
    },

    {
        "name": "Allison Tyson", 
        "comment": "SketchWow I just bought it and it is BRILLIANT!", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Allison-Tyson.png"
    },

    {
        "name": "Alison Brewin", 
        "comment": "I just purchased Sketchwow and am enjoying it.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Alison Brewin.png"
    },

    {
        "name": "Christopher Aguilar", 
        "comment": "Thank you so much!! I'm loving the software, btw!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Christopher.png"
    },
    {
        "name": "Ankit Sharma", 
        "comment": "Awesome tool, I'm using it and it's very easy to create flow diagrams and marketing images.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Ankit-Sharma.png"
    },

    {
        "name": "Anthony Starks", 
        "comment": "SketchWow Thanks for the reply. I really appreciate it... I can honestly say SketchWow has been the most user friendly mind mapping software that I've used...", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Anthony-Starks.png"
    },

    {
        "name": "Barbara Roos", 
        "comment": "I purchased SketchWow pro yesterday and I must say, it's a lot of fun and easy to use! I'm a Photoshop and Illustrator user, but SW will save me so much time. It's perfect for quick sketches for social media, create fun", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Barbara-Roos.png"
    },
    {
        "name": "Ace Hanson", 
        "comment": "This is a great program with tons of options!! Super versatile with lots of amazing features. Thanks for offering a one time license without having subscription!! I got the pro upgrade and it has tons of templates. Please make more YouTube videos on advanced uses", 
        "emoji": "emoji-love", 
        "type": "banner",
        "original_img": "images/All-Testimonials\\FB-Ace-Hanson.png"
    },

    {
        "name": "Claire Cowley", 
        "comment": "I purchased the pro account and have been using it already and I am happy with it so far. I only got it yesterday, but it is very easy to use, and! am enjoying learning more!", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Claire-Cowley.png"
    },

    {
        "name": "Aye Htun", 
        "comment": "Got it yesterday. Can truly recommend this. Already did a whiteboard session over zoom meeting with it.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Aye-Htun.png"
    },
    // {
    //     "name": "Barbara Roos", 
    //     "comment": "I love the software and the frequent updates. I know you guys are continuously working to improve", 
    //     "emoji": "", 
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Barbara-Roos copy.png"
    // },

    {
        "name": "Benjamin Bloor", 
        "comment": "I personally own this. I've shared this post to every Facebook group that I own. SketchWow is worth every single penny. You'll love it!", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Benjamin-Bloor.png"
    },
    {
        "name": "Betsey Matharu", 
        "comment": "Got this deal last week and I can really say that I LOVE ITH!", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Betsey-Matharu.png"
    },
    {
        "name": "Bill Lee Emery", 
        "comment": "I'm loving it - using it in a corporate presentation", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Bill-Lee-Emery.png"
    },

    {
        "name": "Bob Sack", 
        "comment": "I love this app.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Bob-Sack.png"
    },
    {
        "name": "Bob Prohaska", 
        "comment": "Just got my copy as well. I played around with it a bit and it seems super cool. I'm looking forward to trying it on a project!", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Bob-Prohaska.png"
    },
    {
        "name": "Brandon Keath", 
        "comment": "Seriously I love sketchwow, wish I could be an affiliate and just sell it! It is literally hands down one of my top 5 favorite tools that I think every engineer and business owner should have!", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Brandon-Keath.png"
    },
    // {
    //     "name": "Brandon Keath", 
    //     "comment": "One of my favorite products! If your sitting on the fence I can tell you it's worth every penny!", 
    //     "emoji": "emoji-love", 
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Brandon-Keath2.png"
    // },
    {
        "name": "Brian Bates", 
        "comment": "Really good product. I was skeptical. Glad I bought the pro version. And, the latest update addressed some much needed features.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Brian-Bates.png"
    },
    {
        "name": "Brian Bates", 
        "comment": "Was skeptical about this software initially. So glad I went ahead and purchased it and upgraded to pro.  I've used it numerous times in my work and personal life. LOVE IT and there have been some great updates since my purchase.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Brian-Bates2.png"
    },
    {
        "name": "Brian David Hood", 
        "comment": "Already bought it two days ago, used it for an hour long presentation and absolutely loved it. Also, lol to the team for making comic sans the default font when you open it", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Brian-David-Hood.png"
    },
    {
        "name": "Brian Wrasman", 
        "comment": "I can say that I've been using this for a few months now and it is amazing! What really makes me happy is the number of updates and improvements they push out on a regular basis! Still a few minor \u201cwishes\u201d I have but overall it hits everything on my checklist.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Brian-Wrasman.png"
    },
    {
        "name": "Carl Gaudet", 
        "comment": "bought it, no brainer for the price, really good software.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Carl-Gaudet.png"
    },
    {
        "name": "Carol Miller", 
        "comment": "Having lots of fun with this!!! Easy to use and great templates!", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-carol-miller.png"
    },
    {
        "name": "Chris Driscoll", 
        "comment": "Amanda Morris I just bought it. It's super easy and there are some videos on YouTube that explain everything, too. So far...I like it.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Chris-Driscoll.png"
    },
    {
        "name": "Purdeep Sangha", 
        "comment": "Hey Jason,  I think your solution is pretty awesome. We've used a lot of these types of programs. I like yours.  I'm looking forward to seeing how it evolves. Do you have a public roadmap by any chance?  You can consider us a power user. We can provide you with tons of feedback", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Purdeep Sangha.png"
    },

    {
        "name": "David Stoltz", 
        "comment": "I have to say this software is pretty fun to use - it's very creative and produces some great formats - looking forward to hopefully adding transparent backgrounds for animated gifs", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-David-Stoltz.png"
    },

    {
        "name": "Clarice Silvers", 
        "comment": "Sketch wow is growing very nicely, and the customer service is awesome; They listen when customers told them the app needed more ways to upload into the app, not just drag and drop.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Clarice-Silvers2.png"
    },


    {
        "name": "Matt Zimmerman", 
        "comment": "Wow! Amazing tool and nothing else like it on the market. It allows  me to make crazy awesome looking infographics and flowcharts. I  highly recommend this software (unless you are a competitor of mine)!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Matt Zimmerman (BEST).png"
    },

    // {
    //     "name": "Clarice Silvers", 
    //     "comment": "This is an excellent whiteboard app. You can easily use this for storyboarding too, for animation, comics, writing your book...", 
    //     "emoji": "emoji-love", 
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Clarice-Silvers3.png"
    // },
    {
        "name": "Christopher Lemonius", 
        "comment": "Hello! I'm loving the app! So many usecases for my business.", 
        "emoji": "emoji-love", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Christpopher-Lemonius.png"
    },
    {
        "name": "Col Jones", 
        "comment": "Thank you so much guys ,\\  Amazing update, so many new icons and templates that will be great for communicating our ideas.  Keep it up, best sketching app ever", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Col-Jones copy.png"
    },
    // {
    //     "name": "Col Jones", 
    //     "comment": "The gift that keeps on giving This is great value for money Love it!!", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Col-Jones.png"
    // },
    // {
    //     "name": "Col Jones", 
    //     "comment": "Couldn't live without SketchWow, such a great way to create visuals - can't wait for the next update guys", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Col-Jones2.png"
    // },

    {
        "name": "Colleen Parsons", 
        "comment": "Fun program to use with stunning results. My products look both fun and professional. I love SketchWow. (I am actually a customer and they are improving and adding to this program regularly. I'm very happy.", 
        "emoji": "emoji-love",
        "type": "banner",
        "original_img": "images/All-Testimonials\\FB-Colleen-Parsons2.png"
    },

    {
        "name": "David Frey", 
        "comment": "I've used SW now for about 6 months and I LOVE this product. It turns boring into fun and interesting. I use the graphics I make with SW in videos, presentations, and even my upcoming book!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-David-Frey.png"
    },


    // {
    //     "name": "Colleen Parsons", 
    //     "comment": "This is a really fun program to use for explanations and such. I love it.", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Colleen-Parsons.png"
    // },
    // {
    //     "name": "Colleen Parsons", 
    //     "comment": "This is a really fun program to use for explanations and such. I love it.", 
    //     "emoji": "emoji-like",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Colleen-Parsons1.png"
    // },

    {
        "name": "Dan Warburton", 
        "comment": "Ive bought this offer just to create staff work flow charts for my clients, for the price and knowing I'll never need another quick sketch program, it\u2019s brilliant.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Dan-Warburton.png"
    },
    {
        "name": "Dave Coomer", 
        "comment": "Used it for the first time today, dead impressed. Easy to use, and looks great in the PowerPoint.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Dave-Coomer.png"
    },

    {
        "name": "Colin Stevens", 
        "comment": "This is such a great product and the slider option is one of the best features. Just by adjusting the level you can have really casual cartoon hand drawn diagrams or more formal and boring. Thing is - if you work in the online world and as a business need to communicate and sell to customers to earn a living you need tools like this to stand out and be seen. Sales and Marketing is all about eyes, clicks and results so every option like this helps.  Using this tool also makes it easier and much more interesting to explain products, UX, sales funnels, processes and courses than visio or powerpoint.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Colin-Stevens.png"
    },
    {
        "name": "Hank Van Der Merwe", 
        "comment": "Seriously....you have to get this! I have just written a book called \"Yes I Can, A Teenage Resilience Guide\"and I used this program to illustrate the entire book! It is so easy to use!! No more explaining your vision to a graphic designer. Just make it yourself! Drag-and- Drop! Thank You SketchWow this was a game-  changer.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Hank-VanDerMerwe.png"
    },

    {
        "name": "Ce Anne Fernez-Gauthier", 
        "comment": "\u2014  Only very very new in the adventure but playing around with it showed me already that there will be a before and an after in my presentations ! It's easy to pick it up and there are regular emails with lovely updates and great tips to help us improve quickly ! The team behind is so friendly, super reactive and helpful ! Sketch WoW is so brilliant !  I do not always write comments on products but here I think they really deserve to be thanked and recognized ... and for future tempted clients to be reassured about sketchwow\u2019's professionalism _", 
        "emoji": "emoji-like", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Anne-Fernez-Gauthier.png"
    },
    {
        "name": "Dawson Barber", 
        "comment": "SketchWow thank you for your help! Happy to report I bought and love the product.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Dawson-Barber.png"
    },
    {
        "name": "Debbie Benstein", 
        "comment": "I have this and really like it. A lot. And yes, I'ma real person, and no, they didn't ask me to turn up and sing their praises. It's very solid software.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Debbie-Bernstein.png"
    },
    {
        "name": "Dumisani King Zwide", 
        "comment": "I love everything about this software.I bought the full version.It does exactly what they promised .", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Dimisani-King-Zwide.png"
    },
    {
        "name": "Donald Burns", 
        "comment": "I just bought this and it is impressive! 102 I can't wait to use it in my presentations and keynotes!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Donald Burns.png"
    },
    {
        "name": "Edward Levien", 
        "comment": "Just purchased. Up and running in less than 5 minutes. It is EVERYTHING and AS EASY as they say. Wow.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Edward-Levien.png"
    },
    {
        "name": "Eric Ridley", 
        "comment": "I LOVE this app. Bought it last month.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Eric-Ridley.png"
    },
    {
        "name": "Eric Thurman", 
        "comment": "I bought the enhanced version. I am quite happy with it. The learning curve was a lot easier than most graphics software.", 
        "emoji": "emoji-like",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Eric-Thurman.png"
    },
    {
        "name": "Ernesto Glez", 
        "comment": "I just got it is the best tool for me and a great price!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Ernesto-Giez.png"
    },
    {
        "name": "Ivan De la Fuente", 
        "comment": "Jeremy Tangren the software is just awesome much more cheaper than others platforms that charge twice 0 more! It\u2019s 100 value ! I love them !", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Ivan-De-la-Fuente.png"
    },

    {
        "name": "G HR ID OY", 
        "comment": "I bought ut. It's just amazing.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-HR-Id-Oy.png"
    },
    {
        "name": "David Kocsis", 
        "comment": "The best love it so far...", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-David-Kocsis.png"
    },
    {
        "name": "Jack Pot", 
        "comment": "Amazing software, glad to have got it", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Jack-Pot.png"
    },
    {
        "name": "James Darren Davis", 
        "comment": "This is one of the best programs I've bought in the last few years! I love it! Created an email flow chart in just a day.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-James-Darren-Davis.png"
    },
    {
        "name": "Jason Henderson", 
        "comment": "Fabrizio Allavena It's out of this world!", 
        "emoji": "emoji-care", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Jason-Henderson.png"
    },

    {
        "name": "Jennifer Plaisance Porche", 
        "comment": "lam so happy with my purchase! My graphic organizers are wonderful and students love them!!!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Jenifer-Plaisance-Porche.png"
    },
    {
        "name": "Jeff Hutcheson", 
        "comment": "I love this program and use it every day for presentations. The customer service support is incredible. They respond so quickly to questions or things I would like added to the program. It's like they are my best friends who want me to succeed.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Jeff-Hutcheson.png"
    },
    {
        "name": "Jerry Selvaseelan", 
        "comment": "I bought this software and it's brilliantly flexible and creative in its design. Fun to use and churns out unique images as if it's hand drawn, with newly added animations which can be exported as Gifs 3! Class product.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Jerry-Selvaseelan.png"
    },
    {
        "name": "Jill Feather", 
        "comment": "I just bought the Pro Lifetime. Was collaborating with someone from another company and he used Sketchwow. It is different and keeps people interested and engaged. I had to have it!  Why don't you have a LinkedIn page? I want to follow your success!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Jill-Feather.png"
    },
    // {
    //     "name": "Joe Best Bliss", 
    //     "comment": "No affiliate. Evey time I used this software it gets better. Constant updates.", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Joe-Best-Bliss2.png"
    // },
    {
        "name": "Joe Best Bliss", 
        "comment": "I bought it and it is really good. Easy to use. I canceled miro. The only downside is it stored locally. I fixed this by moving the folder to icloud. Worth it.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Joe-Bliss.png"
    },


    
    {
        "name": "Joe Zaccaria", 
        "comment": "SketchWow So I purchased and am very impressed. Because I like to reward companies that provide excellent support and appreciate their customers, I went ahead and took the upgrade as well. Great job and thanks for appreciating the end-users!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Joe-Zaccaria.png"
    },

    {
        "name": "Michael King", 
        "comment": "I absolutely love SketchWow. All I kept saying to myself while learning the program was, \"Oh! That's cool.\"Perfect for all my Lean related content, but can be  used for so much more. Much more aesthetically pleasing and fun to use and view then any program of its type. Thank you!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Michael-King.png"
    },
    {
        "name": "John Carr", 
        "comment": "I can't tell you how many IT projects were better received by stake holders BECAUSE my charts and diagrams looked good. This is a great service, and it allows you to interject personality (and vibe) into your otherwise drab presentations.", 
        "emoji": "emoji-love",
        "type": "banner",
        "original_img": "images/All-Testimonials\\FB-John-Carr.png"
    },

  
    {
        "name": "Kate Davies", 
        "comment": "I love SketchWow - it's the bees knees", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Kate-Davies.png"
    },
    {
        "name": "Joseph S. Kahn", 
        "comment": "It's a killer deal! - software is amazing - can't wait for 2.0 - Get the LTD PRO!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Joseph-Kahn.png"
    },
    // {
    //     "name": "KC Wong", 
    //     "comment": "Bought this yesterday. Fantastic piece of software!", 
    //     "emoji": "emoji-like",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-KC-Wong2.png"
    // },
    // {
    //     "name": "KC Wong", 
    //     "comment": "Bought it last month and loving it so far! Very polished software with constant feature updates/upgrades. This is the Standard version and there is an upsell for the Pro version for just a nominal fee. It was worth the upgrade! My advice: Go all in! You won't regret it! You can thank me later!", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-KC-Wong copy.png"
    // },
    {
        "name": "Lya Rehman", 
        "comment": "SketchWow love this!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Lya-Rehman.png"
    },
    {
        "name": "KC Wong", 
        "comment": "Bought this software + then upgraded to the Pro version yesterday! This software is very polished and really blows my mind! I'm so glad that I pulled the trigger and got this software after months of procrastination & deliberation! But at least my instinct was spot-on - it turns out to be a fantastic addition to my business toolbox!  Jason, take this software to the moon and beyond +#! Keep your emails coming to my inbox coz I love the humor that you've injected in each of them! Best of luck!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-KC-Wong.png"
    },


    {
        "name": "Richard Berry", 
        "comment": "I got it a few days ago. I've already created several charts for educating my customers that look incredible, not at all like usual diagramming or PowerPoint, although with a little work I could turn it into an animated PP presentation. These are designed for print to go with my video series.  The templates are great for getting started, getting ideas for an approach and such, but I love that you can start from scratch so you're not locked in, AND you can do traditional formal flowcharts like Visio, which I used to use years ago.  The WOW part of the name is correct!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Richard-Berry.png"
    },
    // {
    //     "name": "Kenneth Gonzalez", 
    //     "comment": "I think SketchWow is an awesome product. Definitely one of my better purchases. Getting a lot of usage out of it and will be using it a lot more! .", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Kenneth-Gonzalez.png"
    // },
    // {
    //     "name": "Kenneth Gonzalez", 
    //     "comment": "SketchWow is a fabulous product and I love using it. It's been a real game changer for me. The full version is worth every penny!", 
    //     "emoji": "", 
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Kenneth-Gonzalez2.png"
    // },
    // {
    //     "name": "Kimberly Norton", 
    //     "comment": "So far i love this software", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Kimberly-Norton.png"
    // },
    {
        "name": "Matteo Piattoli", 
        "comment": "Jens Skov there's a 30-day money back guarantee which is more or less the same but still, the program is outrageously powerful and useful. Once you get the hang of it (which is still not that hard at all, just a few aspects which you need to \"understand\") you can create entire pages in a matter of minutes.  I'm the least creative person in the world but I'll be damned, after less than 30 minutes of experimentation and a little trial-and-error I already feel like I can confidently use no less than 90% of the features", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Matteo-Piattoli.png"
    },
    {
        "name": "Kimberly Norton", 
        "comment": "I love this program. Can't wait for version 2.0", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Kimberly-Norton2.png"
    },
    {
        "name": "Laurie Brown", 
        "comment": "I love this software! It is my new go to tool", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Laurie Brown2.png"
    },
    // {
    //     "name": "Laurie Brown", 
    //     "comment": "I love this software! It is my new go to tool", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Laurie-Brown.png"
    // },
    {
        "name": "Lee Wisener", 
        "comment": "I bought using the pro deal and really love the software. Turned me into a sketcher in no time", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Lee-Wisener.png"
    },
    {
        "name": "@luisivan.delafuente", 
        "comment": "I already bought it and is very nice and easy to manage, live it!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Luis.png"
    },

    // {
    //     "name": "Lya Rehman", 
    //     "comment": "Humberto Bortolossi honestly you won't want to just test it. You will want it. It's worth it. Get the pro verison.", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Lya-Rehman2.png"
    // },
    // {
    //     "name": "Magus Stirling", 
    //     "comment": "I bought it yesterday .it's breathtaking", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Magus-Stirling.png"
    // },
    {
        "name": "Mark A. Coudray", 
        "comment": "Thsi is a very good product. I bought this deal last year and use it almost every day. Great deal, fun to use, and very effective. I highly recommend. re)", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Mark-Coudray.png"
    },
    // {
    //     "name": "Magus Stirling", 
    //     "comment": "Best 49 ever spent", 
    //     "emoji": "emoji-love",
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\FB-Magus-Stirling3.png"
    // },

  
    {
        "name": "Mariela Quintero", 
        "comment": "Excelent product. They're constantly making it better and enhancing features. Have had it for a couple of years now.  5h \\v)", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Mariela-Quintero.png"
    },

    {
        "name": "Magus Stirling", 
        "comment": "Sketchwow is one of my fav ever", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Magus-Stirling2.png"
    },
    {
        "name": "Mark Schlesinger", 
        "comment": "Looks absolutely fantastic", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Mark-Schlesinger.png"
    },
    {
        "name": "Marla Mac", 
        "comment": "LOVE my SketchWow!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Marla-Mac.png"
    },
    {
        "name": "Martin Hamilton", 
        "comment": "Very good software and easy to use. I love it and enjoy making super unique one-of-a-kind images and pdf presentations (with links to my blog posts).", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Martin-Hamilton.png"
    },
    {
        "name": "Martin Kominek", 
        "comment": "Its worth of buying. I got Pro upgrade and its super easy to use. Great for workbooks and presentations.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Martin-Kominek.png"
    },
    {
        "name": "Matt Zimmerman", 
        "comment": "Malte Herrmann I bought it and it\u2019s incredible. I'm totally blown away and it's a steal at the current price.", 
        "emoji": "emoji-care", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Matt-Zimmerman.png"
    },

    {
        "name": "Michael King", 
        "comment": "I absolutely love SketchWow. All I kept saying to myself while learning the program was, \"Oh! That's cool.\"Perfect for all my Lean related content, but can be  used for so much more. Much more aesthetically pleasing and fun to use and view then any program of its type. Thank you!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Michael-King.png"
    },
    {
        "name": "Michael Spremulli", 
        "comment": "I purchased the advanced version the other day. I must  say that I was pleasantly surprised. This is a really robust program and easy to use. Is there a FB group or community for users?", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Michael-Spremulli.png"
    },

    {
        "name": "Mishra Bhagirathi", 
        "comment": "Wow its really great , Because on online journey every one need to create attractive content with creative presence on this its will help to reduce their time, effort and money . Thanks to person who created this for helping online marketers .", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Mishra-Bhagirathi.png"
    },
    {
        "name": "Minna Suto", 
        "comment": "SketchWow It is a fabulous programme / app!! I'm having so much fun with it.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Minna-Suto.png"
    },
    {
        "name": "Miriam Gilbert", 
        "comment": "Bought the pro version and love it! Cuts down the time to prepare diagrams, certain type of graphics etc so much! And lots of updates too!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Miriam-Gilbert.png"
    },
    {
        "name": "Mike Schwarz", 
        "comment": "Bought it some days ago and love it. Does what it says: Quick drawing of sketches and workflows.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Mike-Schwartz.png"
    },
    {
        "name": "Colleen Parsons", 
        "comment": "SSURSE  Like  S  In my opinion, this program is worth every penny.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-multiple-comments.png"
    },
    {
        "name": "NadiaNa Lento", 
        "comment": "I GOT IT ! IT IS AN AWESOME APP!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-NadiaNa-Lento.png"
    },

    {
        "name": "Neil A Schneider", 
        "comment": "This is a great app!!!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Neil Schneider.png"
    },
    {
        "name": "Natashja Troskie", 
        "comment": "SketchWowzers is TRULY AMAZING, I have used the application to create graphics for my website, content and adore simply doodling around. Here is an additional GREAT!!!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Natashja Troskie.png"
    },
    {
        "name": "Nate McCallister", 
        "comment": "Highly recommend. I thought Canva was enough but this is totally an awesome addition. Did a presentation using graphics I made entirely from sketch wow and at least 7 people asked me how I did it.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Nate-Mccallister.png"
    },
 
    {
        "name": "Nat Green", 
        "comment": "I bout this a couple days ago and am really satisfied with the software as well as their support. They're really on top of it!", 
        "emoji": "emoji-like",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Nat-Green.png"
    },
    {
        "name": "Ni Willis", 
        "comment": "Sketchwow is awesome. Love the new updates!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Ni-Willis.png"
    },
    {
        "name": "Ni Willis", 
        "comment": "Love sketchwow. The support is also excellent, and the updates are amazing.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Ni-Willis2.png"
    },
    {
        "name": "Olivier Bareau Priv", 
        "comment": "Bought it and I don't regret it and already used ... GREAT JOB", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Olivier-Bareau-Prive.png"
    },
    {
        "name": "Pam Blizzard", 
        "comment": "I rarely post on ads for things I bought, but I really like this little tool. The \"hand drawn\" feature makes my designs more relatable to my audience. Keep the updates coming", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Pam-Blizzard.png"
    },
    {
        "name": "Pamela Bowen", 
        "comment": "I have the pro version and love it. I'm a Visio expert and still use it frequently but this makes it so much easier to craft \u201chand drawn\" flowcharts for marketing. Customer service is top notch too.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Pamela-Bowen.png"
    },
    {
        "name": "Paul Colaianni", 
        "comment": "I bought it too! Love it.  Feature request: need side view of faces so I can create people talking to each other. Still awesome software no matter what.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Paul-Colianni.png"
    },
    {
        "name": "Paul Smietan", 
        "comment": "I'm using this ALL the time now. Visio is collecting dust", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Paul-Smietan.png"
    },
    {
        "name": "Peter Odehnal", 
        "comment": "Excellent diagramming software. Thanks for continuing to add requested features. My goal is to use SketchWow to illustrate concepts during live Zoom  calls.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Peter-Odehnal.png"
    },
    {
        "name": "Rebecca Pearce", 
        "comment": "I bought this today to create visuals for teaching and I absolutely love it!! It makes a real change to the kind of diagrams that I used before. I can't wait to use this more and more in my teaching in the future", 
        "emoji": "emoji-like",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Rebecca-Pearce.png"
    },
    {
        "name": "Richard Berry", 
        "comment": "Guys: wanted to compliment you on a great app with a fresh take on diagramming. I've already had very strong reactions to what I've put together. A colleague called me today, asking if I'd mind if she used some of my diagrams in her marketing (with credits). I love it! It helps a friend AND my clientele.  Great job!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Richard-Berry copy.png"
    },
    {
        "name": "Kenneth Gonzalez", 
        "comment": "I use the stuffing out of SketchWow and think the product is fabulous! I've not found anything I cannot do. I like the fact that I can build things which look more like the diagrams I draw in working sessions with people. It helps my material stand out.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Kenneth-Gonzalez copy.png"
    },
    {
        "name": "Richard Bezuidenhout", 
        "comment": "I think this tool is so underrated. It\u2019s should be a standard for anyone not wanting to be boring. You can still create boring stories and presentations, but once you get started you wouldn't want to", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Richard-Bezuidenhout.png"
    },
    {
        "name": "Roberto Brisciani", 
        "comment": "I bought it and it\u2019s an impressive tool which value it's 100 times it's price. Thank you we love it", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Roberto-Brisciani.png"
    },
    {
        "name": "@sanjaynaidoo", 
        "comment": "Great software, just purchased, very simple to map out flow plans, well done to the developers", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-sanjay.png"
    },
    {
        "name": "Sarah Kesty", 
        "comment": "I actually use it and really like it. Their customer service is So good, too", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Sarah-Kesty.png"
    },
    {
        "name": "scottpattphoto", 
        "comment": "I just downloaded the software. It's breathtaking!! I can't reply to Jason atm since I'm already creating a presentation for Thursday. I can\u2019t believe it's taken this long to find you.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Scott-Patt.png"
    },
    {
        "name": "Shan Shah", 
        "comment": "I just bought it! SketchWoW Pro. The tool is incredible! Hope to see more features and pre- made templates inside.  Thank You", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Shan-Shah-animation.gif"
    },
    {
        "name": "Shariah Ihsa", 
        "comment": "Not sure why you guys aren't getting more likes so hopefully this will wake up the algorithm. Purchased SW a while back & it's PHE-NO-ME-NAL! Don't know how come it's only $49... And don't y'all ever sleep??", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Sharian-Ihsan.png"
    },
    {
        "name": "Sherice Kral", 
        "comment": "I've been using this recently in my client lessons and everyone is IN LOVE!  I love the easy usability too.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Sherice-Kral.png"
    },
    {
        "name": "Sophie Juliet", 
        "comment": "This is absolutely fantastic! Love it", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Sophie-Juliet.png"
    },

    {
        "name": "Steve Coates", 
        "comment": "I'm loving this program, using it mostly within Articulate Rise 360", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Steve-Coates.png"
    },
    {
        "name": "Marco Cirillo", 
        "comment": "I bought this a month ago and it changed the way our website looks! I love how you can personalise your photos, create animated and eye-catchy workflows in minute, and import some of the icon in photoshop to create transparent background icons to use on videos! Yes, we also use Photoshop but for quick retouches this software is y. I bought the Pro right away so we access all the features and templates. I am not sure what's in the basic plan. Definitely recommended.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Marco-Cirillo.png"
    },
    {
        "name": "Steve Hutson", 
        "comment": "Have purchased the Pro plan - extremely happy and excited to use - I'm an engineer and educator and power user of professional charting software - have been looking specifically for \"hand drawn style\"charting for no other reason to share my knowledge with a non-engineering audience in video tutorials - this does the job 100% and far more efficient than pro engineering software that has \"hand drawn elements\"for this case use :)", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Steve-Hudson.png"
    },
    {
        "name": "@beard_wiley", 
        "comment": "I really like this software. We've used this at work to create some really nice flyers for things like raffles, announcements, etc. We've gotten a lot of positive feedback about how much better they look than in previous years. They come out with regular updates, the devs listen to users feedback, and support has always been quick and helpful.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\IG-Beard-Wiley.png"
    },

    {
        "name": "Suntar Jono", 
        "comment": "Thx SketchWow I really love sketchwow. Please update animation icon  \u00a9. It's really cute. I can't wait every update from sketchwow.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Suntar-Jono.png"
    },
    {
        "name": "Suraj Sanjay Bhosale", 
        "comment": "the product is very good. Have just used it for 2 days and started liking it", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Suraj-Sanjay-Bhosale.png"
    },
    {
        "name": "TBalasubramanian lyer", 
        "comment": "I have pro version i strongly recommend this software for people who want put the ideas in casual manner but real appealing way. All things are available within the software. Trying is believing", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Tbalasubrananian Iyer.png"
    },
    {
        "name": "Teresa Badalamenti", 
        "comment": "I am currently using SketchWow. It's absolutely awesome! User friendly, great tutorial video, and produces great content!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Teresa-Badalamenti.png"
    },
    {
        "name": "@Tom Atkins", 
        "comment": "I love this software!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Tim-Atkins.png"
    },
    
    {
        "name": "TJ Jacobs", 
        "comment": "I bought it, as I have a diagram to complete. This is not only more interesting but so much easier to navigate. Love it!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-TJ-Jacob.png"
    },
    {
        "name": "Tom Greger Knutsen", 
        "comment": "Tunde Ebidero it is great! I got the pro version right away. Not sure if I need pro version, but I just loved the style so much that I had to get it", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Tom-Greger-Knutsen 2.png"
    },
    {
        "name": "Tom Greger Knutsen", 
        "comment": "Really nice onboarding ,y I got the Pro version just in case & I just love it!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Tom-Greger-Knutsen.png"
    },
    {
        "name": "Valerie DuVall", 
        "comment": "Real customer here - don't know these guys at all - great little app that does exactly as it says as easy as it says!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Valerie-DuVall.png"
    },

    {
        "name": "Twenty Twenty", 
        "comment": "SketchWow is awesome!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Twenty-Twenty.png"
    },
    {
        "name": "Trisha Cupra", 
        "comment": "It's better than I expected", 
        "emoji": "emoji-like",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Trisha-Cupra.png"
    },
    {
        "name": "Valter Ngako", 
        "comment": "I tried this software it's just Wow", 
        "emoji": "emoji-like",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Valter.png"
    },
    {
        "name": "Veronica Pullen", 
        "comment": "I have been enjoying playing with this It's fun and easy to use. QO1", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Veronica-Pullen.png"
    },
 
    {
        "name": "Wade Neumann", 
        "comment": "Yeah, it's a nice experience to get a 'clean' deal. I thought it was a great experience how they handled that!", 
        "emoji": "emoji-like",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Wade-Neumann.png"
    },
    {
        "name": "Nelson Walker", 
        "comment": "This program is awesome. Very easy to use and makes  great graphics.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FN-Nelson-Walker.png"
    },

    {
        "name": "@otterspoolstudios", 
        "comment": "Bought this - love it - really well done", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\IG-otterpool.png"
    },
    {
        "name": "Steve Kang", 
        "comment": "I really like this tool! I've been using it to create flow diagrams for a book I'm writing. I really like the v1.5 updates.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Steve-Kang.png"
    },
    {
        "name": "@ejmoonshot", 
        "comment": "Just getting started with your software but it's already indispensable. Love it. Thank you", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\IG-EJ-Moonshot.png"
    },
    {
        "name": "@luisivan.delafuente", 
        "comment": "This is really a different way for presenting. It\u2019s a huge canvas, easy and funny \u00a9. 1 am very happy", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\IG-Luis.png"
    },


    {
        "name": "Minka Dumont", 
        "comment": "Hi wonderful people at Wow!  Wow indeed, I am LOVING the software - this fits my needs so well and I will be making amazing learning-adventures for primary school kids with this program.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Minka-Dumont.png"
    },
    {
        "name": "scottpattphoto", 
        "comment": "I just downloaded the software. It's breathtaking!! I can't reply to Jason atm since I'm already creating a presentation for Thursday. I can\u2019t believe it's taken this long to find you.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\IG-Scott-Patt.png"
    },


    {
        "name": "Henry Marketing Co", 
        "comment": "Hey Jason,  First, SketchWOW is awesome. I was not expecting to get as much as I did for the money - so bravo! And your copywriting is SPOT ON AWESOME!!!", 
        "emoji": "", 
        "type": "banner",
        "original_img": "images/All-Testimonials\\Henry Marketing.png"
    },
    {
        "name": "Viswanathan Thangavelu", 
        "comment": "This is opinion about sketchwow from layman view, iam basically an realestate appraiser / valuer in India, looking for a software to draw simple diagram and maps quickly and easily  lam really wowed by your software, it is so simple and result is such a beautiful, I had been using the software for about five hours, and very much impressed by it, there is a craftiness behind the making of software, for the last twenty years I have used many software, your sketchwow is par excellence  I have used following software 1. Image mark up  2.corel draw  3.pdf annotator  4. Fast tone capture  5. Simple diagram  Your software excels and satisfy my requirements wholesomely, specially the hand drawn effect is giving premium, architect drawn look.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\India.png"
    },
    {
        "name": "Julie Christy", 
        "comment": "Real customer here. Bought Sketchwow in May 2022 + Pro upgrade. It's one of the best software purchases I've ever made.  From my observation, Sketchwow is not some software that developers just developed but never bother to upgrade and improve.  Rather, Sketchwow is constantly being improved with more functions added. Check its FB page for all the software updates.  I thank the team for putting in so much effort to make Sketchwow better and better.  I love the templates and user-friendly UI. & I can install the software on 3 computers.  Currently, I'm using Sketchwow to design my ebooks, explainer videos and social media posts. I run an e- learning biz and also do content marketing for other biz.  So yeah, seriously, buy this and use it for your work or business. It's super useful.", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\FB-Julie-Christy.png"
    },
    {
        "name": "Ravi Kiran", 
        "comment": "This is such a wonderful tool .. iam blown away... This saves me so much time and efforts if ihad to use different tools like PhotoShop or Powerpoint to create something similar. Recently i did a test by using Sketch Wow and another tool .. In Sketch Wow it took about 9 Minutes and on the other tool it took about 52 Minutes.. so i saved around 41 Minutes. I would average create around 30-40 such images .. and if you calculate around 35 images.. and multiply with an average of 40 Minutes.. saving.. this saves me easily around 2-3 days of work in a month. I would love to see more template ..", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\YT-Ravi-Kiran.jpeg"
    },
    {
        "name": "Jean-Philippe Leblanc", 
        "comment": "Brilliant! Worked like a charm. I have been doing \"slides\" and diagrams for a pretty long time. I have to say your product is awesome", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Jean-Philippe Leblanc.png"
    },
    {
        "name": "Jennifer Tracy", 
        "comment": "Mike Nelson it's worth it! One time purchase verse yearly subscription - love it! Total was around 100 for the entire thing!", 
        "emoji": "emoji-like",
        "type": "comment",
        "original_img": "images/All-Testimonials\\Jennifer Tracy.png"
    },
    {
        "name": "John Vrakking", 
        "comment": "Hi,  Thanks for the update and keep the good things", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\John-Vrakking.png"
    },
    {
        "name": "Karl G Olson", 
        "comment": "Thanks, Jason, upgraded! Great software!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Karl.png"
    },
    {
        "name": "KC Wong", 
        "comment": "A fantastic piece of software! Highly recommended!", 
        "emoji": "emoji-love",
        "type": "comment",
        "original_img": "images/All-Testimonials\\KC-Wong.png"
    },
    {
        "name": "Kimberlee Vollbrecht", 
        "comment": "Thanks you so much! This is incredibly helpful, and I'll definitely recommend Sketch Wow to other!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Kimberlee.png"
    },
    {
        "name": "ks", 
        "comment": "Hi Jason, SketchWow is fun to use!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\KS.png"
    },
    {
        "name": "Laurie Brown", 
        "comment": "I LOVE it!!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\Laurie Brown.png"
    },
    {
        "name": "Bryan Toder", 
        "comment": "Hi. I'm using SketchWow (I got the PRO version!) and LOVE it.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\OP-FB-Bryan Toder.png"
    },
    // {
    //     "name": "Matt Zimmerman", 
    //     "comment": "Jason, Awesome software! I'm super pumped! I plan on using it to make awesome blog infographics. The \"fun\" look of your software has the  perfect blend of fun but professional while still being super easy to use.", 
    //     "emoji": "", 
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\Matt Zimmerman2.png"
    // },

    // {
    //     "name": "Minka Dumont", 
    //     "comment": "Hi wonderful people at Wow! Wow indeed, I am LOVING the software - this fits my needs so well", 
    //     "emoji": "", 
    //     "type": "comment",
    //     "original_img": "images/All-Testimonials\\Minka2.png"
    // },


    {
        "name": "Cody Burch", 
        "comment": "Hello! I have a cool case study. I used SketchWow to make my marketing frameworks. Then I  printed them off and laminated them. I turned it into a free plus shipping offer. It's generated about $10k in revenue since launching 2 months ago.  I've been asked to write a Medium article about the whole process and wanted to touch base with you before launching", 
        "emoji": "", 
        "type": "banner",
        "original_img": "images/All-Testimonials\\\uf021FB-Cody-Burch.png"
    },
    {
        "name": "3-Minute Real Estate", 
        "comment": "This is one of those packages that actually surpasses the hype. Love this package and use it regularly. I already have planned uses for the update", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\YT-testimonial11.png"
    },


    {
        "name": "Chris Bloor", 
        "comment": "Folks, this really is exceptional and worth every single penny!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\OP-FB-Chris Bloor.png"
    },


    {
        "name": "Alberto Cohen", 
        "comment": "I'm still loving Sketchwow! From the very moment you launched it up until now, I'm fully satisfied with the pace of change, the usefulness and the balance between simplicity and features.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\YT-testimonial12.png"
    },
    {
        "name": "Gene Menor", 
        "comment": "Jason and Company, you guys \"rock!\"", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\YT-testimonial5.png"
    },
    {
        "name": "Bradley Smith", 
        "comment": "Just gets better and better - all I can sat SketchWow is Wow!!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\YT-testimonial6.png"
    },
    {
        "name": "Martin Hamilton", 
        "comment": "Very nice. I own the Pro version! It's very useful and unique.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\YT-testimonial7.png"
    },
    {
        "name": "Drew Andrews", 
        "comment": "It's wonderful having a software that is constantly updated and supported.", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\YT-testimonial8.png"
    },
    {
        "name": "Antony Sutton", 
        "comment": "Fantastic!! So many simple improvements that just make it better to use. Thankyou!!!", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\YT-testimonial9.png"
    },
    {
        "name": "Tiger Cub Media", 
        "comment": "LOVE IT thank you so much guys", 
        "emoji": "", 
        "type": "comment",
        "original_img": "images/All-Testimonials\\YT-testimonial10.png"
    },



    {
        "name": "Stephen Shapiro", 
        "comment": "I bought the Pro version. Love it. I was going to end up paying significantly more for just one hand drawn graphic. Now I can do it myself and make edits. And the customer support was excellent. I had a question and they responded immediately. I also like that it is a download and not cloud-based. I find the app versions of software a lot more user friendly.", 
        "emoji": "emoji-like",
        "type": "banner",
        "original_img": "images/All-Testimonials\\FB-Stephen-Shapiro.png"
    },
]