import React, {
  ReactElement,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import uniqueId from "lodash/uniqueId";
import { ReviewsItem } from "./types";
import * as Styled from "./styles";
import useVisibleOnScroll from "../../hooks/useVisibleOnScroll";
import  axios  from 'axios';

interface ReviewsProps {
  data: ReviewsItem[];
  title: ReactElement ;
  subtitle: ReactElement;
}

interface ReviewsDataItem extends ReviewsItem {
  id: string;
}

export const Reviews = ({
  data,
  title,
  subtitle,
}: ReviewsProps): ReactElement => {
  const memoizedData = useMemo(
    () =>
      data.map(
        (item): ReviewsDataItem => ({
          ...item,
          id: uniqueId(),
        })
      ),
    [data]
  );


  // let [imgSrc, setImgSrc] = useState("/reviews/profiles/placeholder.png");

  // const CheckImage = (path) => {
  //   CheckIfImageExists(path, (exists) => {
  //     //console.log(exists, path)
  //     if (exists) {
  //        setImgSrc = path;
  //        //return true;
  //     } else {
  //        setImgSrc = "/reviews/profiles/placeholder.png";
  //       //return false;
  //     }
  //   });
  //   // axios
  //   //   .get(path)
  //   //   .then(() => {
  //   //     //console.log(path)
  //   //     return true;
  //   //   })
  //   //   .catch(() => {
  //   //     console.log(path)
  //   //     return false;
  //   //   });
  // }

  // const CheckIfImageExists = (url, callback) => {
  //   const img = new Image();
  //   img.src = url;

  //   if (img.complete) {
  //     callback(true);
  //   } else {
  //     img.onload = () => {
  //       callback(true);
  //     };
      
  //     img.onerror = () => {
  //       callback(false);
  //     };
  //   }
  // }

  // useEffect(() => {
  //   console.log('imgSrc', imgSrc)
  // }, [imgSrc])

  //const [activeQuote, setActiveQuote] = useState(memoizedData[0].id);
  const testimonialsRef = useRef(null);
  const { isVisible } = useVisibleOnScroll(testimonialsRef);

  const cards = () => {
    let reviews = [];
    memoizedData.map((review, index) => {
      
      if (review.type == "banner") {
        reviews.push(<Styled.Banner key={review.id}>
          
          <Styled.BannerQuote>
          <Styled.BannerStars>
            <img src="/reviews/star.svg"/>
            <img src="/reviews/star.svg"/>
            <img src="/reviews/star.svg"/>
            <img src="/reviews/star.svg"/>
            <img src="/reviews/star.svg"/>
          </Styled.BannerStars><br/><br/>
            {/*review.comment.split('/n').map(comment => ( */ }
                <Styled.BannerQuoteContent title={review.comment.split('/n').join(' ')}>
                "
                {
                    //comment
               
                    review.comment.split('/n').join(' ')
                }
                "
                </Styled.BannerQuoteContent>
              { /*))*/ }
              <Styled.BannerNameContainer>
                
                <Styled.Author> 
                  <img src={"/reviews/profiles/"+review.name+".png"}/> {review.name}</Styled.Author>
                {review.emoji != "" && 
                  <Styled.Emoji><img src={"/reviews/"+review.emoji+".svg"}/></Styled.Emoji>
                }
              </Styled.BannerNameContainer>
          </Styled.BannerQuote>
          <Styled.BannerIcons>
              
              <img className="qoute" src="/reviews/qoute.svg"/>
              <img className="megaphone" src="/reviews/megaphone.svg"/>
          </Styled.BannerIcons>
        </Styled.Banner>)
      }else{
        reviews.push(<Styled.Card key={review.id}>
          <Styled.Quote>
            {/*review.comment.split('/n').map(comment => ( */ }
                <Styled.QuoteContent>
                "
                {
                    //comment
               
                    review.comment.split('/n').join(' ')
                }
                "
                </Styled.QuoteContent>
              { /*))*/ }

          </Styled.Quote>
          <Styled.NameContainer>
             
             <Styled.Author> 
               <img src={"/reviews/profiles/"+review.name+".png"}/> {review.name}</Styled.Author>
             {review.emoji != "" && 
               <Styled.Emoji><img src={"/reviews/"+review.emoji+".svg"}/></Styled.Emoji>
             }
           </Styled.NameContainer>
        </Styled.Card>)
      }
    })

    return reviews;
  }

  return (
    <Styled.Wrapper isVisible={isVisible} ref={testimonialsRef}>
      {/* <Styled.WrapperBG>test</Styled.WrapperBG> */}
      <Styled.Heading>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Subtitle>{subtitle}</Styled.Subtitle>
      </Styled.Heading>
          <Styled.Cards>
            <Styled.CardContainer>
              {cards()}
            </Styled.CardContainer>
          </Styled.Cards>
          <Styled.Doodle />

      { /*
      
        <Styled.Arrow
          src="/testimonials-arrow.webp"
          srcSet="/testimonials-arrow.webp 1x, /testimonials-arrow@2x.webp 2x"
          alt="Arrow"
          loading="lazy"
    /> */}
    </Styled.Wrapper>
  );
};
